import React, { useEffect, useState } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { db } from "../../firebase/config";
import { Typography, Paper, IconButton, Button } from "@material-ui/core";
import BackspaceIcon from "@material-ui/icons/Backspace";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { toast } from "react-toastify";
import { Form, Input } from "../../components";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import AddIcon from "@material-ui/icons/Add";
import { useSelector, useDispatch } from "react-redux";
import { setLoading } from "../../redux/publicReducer/actions";

export const ModifierCollection = () => {
  const { user } = useSelector((state) => state.public);
  const dispatch = useDispatch();
  const [collections, setCollections] = useState([]);
  const [modifier, setModifier] = useState({});
  const match = useRouteMatch();
  const history = useHistory();
  const modifierId = match.params?.modifierId;
  const modifierRef = db
    .collection("admins")
    .doc(user.id)
    .collection("productOptions")
    .doc(modifierId);

  const initialValues = {
    name: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Enter an option"),
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const handleSubmit = (data) => {
    console.log(data);
    dispatch(setLoading(true));
    modifierRef
      .collection("productOptionsValue")
      .add({ ...data })
      .then(() => {
        toast.success("Option Added");
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    const unsub = modifierRef.onSnapshot((doc) => {
      setModifier(doc.data());
    });

    return unsub;
  }, []);

  useEffect(() => {
    const unsub = modifierRef
      .collection("productOptionsValue")
      .onSnapshot((snapshot) => {
        const _collections = snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });

        setCollections(_collections);
      });

    return unsub;
  }, []);

  console.log(match, "matches");
  return (
    <div>
      <Button
        variant="contained"
        color="secondary"
        style={{ marginBottom: "1rem" }}
        onClick={() => {
          history.goBack();
        }}
      >
        <ArrowBackIcon />
      </Button>
      <Typography variant="h5">{modifier.optionName}</Typography>

      <div style={{ margin: "1rem 0rem" }}>
        <Form provider={methods} onSubmit={handleSubmit}>
          <div>
            <Input
              name="name"
              endadornment={
                <IconButton type="submit">
                  <AddIcon />
                </IconButton>
              }
            />
          </div>
        </Form>
      </div>
      {collections.map((collection, index) => {
        return (
          <Paper
            key={index}
            style={{
              margin: "1rem 0rem",
              padding: "1rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography>{collection.name}</Typography>
            <IconButton
              onClick={() => {
                console.log("to delete");
                dispatch(setLoading(true));
                modifierRef
                  .collection("productOptionsValue")
                  .doc(collection.id)
                  .delete()
                  .then(() => {
                    toast.success("Collection deleted");
                    dispatch(setLoading(false));
                  });
              }}
            >
              <BackspaceIcon color="secondary" />
            </IconButton>
          </Paper>
        );
      })}
    </div>
  );
};
