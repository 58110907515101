import React, { useState, useEffect } from "react";
import { Form, Input } from "../../components";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  Grid,
  Typography,
  IconButton,
  InputAdornment,
  Button,
  Paper,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import * as Yup from "yup";
import { auth } from "../../firebase/config";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

export const AdminLogin = () => {
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();
  const initialValues = {
    email: "",
    password: "",
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Enter a valid email")
      .required("Email is required"),
    password: Yup.string()
      .required("Please enter your password")
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()-_=+{};:,<.>]){1})(?=.*d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must contain at least 8 characters, one uppercase, one number and one special case character"
      ),
  });
  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const handleSubmit = (data) => {
    console.log(data);
    auth
      .signInWithEmailAndPassword(data.email, data.password)
      .then(() => {
        history.push("/admin");
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={12} md={6} lg={4}>
          <Paper
            style={{
              padding: "1rem",
              minHeight: "50vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              borderRadius: "10px",
            }}
          >
            <Typography
              style={{ textAlign: "center", margin: "1rem 0rem" }}
              variant={"h4"}
              color="primary"
            >
              Welcome to MorningSide
            </Typography>
            <Form onSubmit={handleSubmit} provider={methods}>
              <Input name="email" label="Email" variant="outlined" />
              <Input
                name="password"
                label="Password"
                type={!showPassword ? "password" : "text"}
                variant="outlined"
                endadornment={
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {!showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <div style={{ marginTop: "0.5rem" }}>
                <Button variant="contained" color="primary" type="submit">
                  Login
                </Button>
              </div>
            </Form>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
