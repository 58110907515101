import "bootstrap/dist/css/bootstrap.min.css";
import  SignIn  from "../eComm/components/pages/homepage/SignIn";
import  Sample  from "../eComm/components/pages/homepage/Sample";
import { useCookies } from "react-cookie";
import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@material-ui/core";
const serverId = process.env.REACT_APP_SERVER_ID
const siteId = process.env.REACT_APP_SITE_ID

export const HomepageLanding = () => {
  const [openWelcome, setOpenWelcome] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [cookieSICName, setCookieSICName, removeCookieSICName] = useCookies([
    "sic_name",
  ]);

  const getCookie = (key) => {
    var keyValue = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");
    return keyValue ? keyValue[2] : null;
  };

  const setCookie = (key, value) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + 365 * 24 * 60 * 60 * 1000);
    document.cookie =
      key + "=" + value + ";expires=" + expires.toUTCString() + ";path=/";
  };

  useEffect(() => {
    var verifyOrderProcess = new EventSource(
      "https://ecommv2.servingintel.com/postpayment/verifyorderprocess/"+siteId+"/" +
        getCookie("orderId")+"?server_id="+serverId
    );
    verifyOrderProcess.onmessage = function (event) {
      if (event.data === 1) {
        verifyOrderProcess.close();
        setCookie("orderId", "");
      }
    };
  }, []);

  //   useEffect(() => {
  //     if (cookieSICName) {
  //       setOpenWelcome(true);
  //     }
  //   }, []);

  const handleCloseWelcome = () => {
    setOpenWelcome(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Dialog
        open={openWelcome}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Welcome Back!"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{ "text-align": "center" }}
            id="alert-dialog-description"
          >
            <b>{cookieSICName.sic_name}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ "text-align": "center", display: "block" }}>
          <Button onClick={handleCloseWelcome} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <div className="App">
        <SignIn  />
      </div>
    </>
  );
};
