import React, { useState, useEffect } from "react";
import { Form, Input } from "../../components";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  Grid,
  Typography,
  IconButton,
  InputAdornment,
  Button,
  Paper,
  Divider,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import * as Yup from "yup";
import { auth } from "../../firebase/config";
import { toast } from "react-toastify";
import { useHistory, useLocation, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/publicReducer/actions";
import { signInWithGoogle } from "../../firebase/config";

export const Signin = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { user } = useSelector((state) => state.public);
  const dispatch = useDispatch();
  const { state } = useLocation();
  console.log(state, "state");
  const history = useHistory();
  const initialValues = {
    email: "",
    password: "",
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Enter a valid email")
      .required("Email is required"),
    password: Yup.string()
      .required("Please enter your password")
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()-_=+{};:,<.>]){1})(?=.*d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must contain at least 8 characters, one uppercase, one number and one special case character"
      ),
  });
  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const handleSubmit = (data) => {
    dispatch(setLoading(true));
    console.log(data);
    auth
      .signInWithEmailAndPassword(data.email, data.password)
      .then(() => {
        if (state?.skip) {
          return history.push(state.menu);
        }
        if (state?.restaurantId) {
          history.push(`/${state?.restaurantId}/checkout`);
        } else {
          history.push("/home");
        }
        dispatch(setLoading(false));
      })
      .catch((error) => {
        toast.error(error.message);
        dispatch(setLoading(false));
      });
  };

  const handleGoogleSignIn = () => {
    signInWithGoogle().then(() => {
      if (state?.skip) {
        return history.push(state.menu);
      } else {
        if (state?.restaurantId) {
          history.push(`/${state?.restaurantId}/checkout`);
        } else {
          history.push("/home");
        }
      }
    });
  };

  if (user) {
    if (user?.userType === "CUSTOMER") {
      if (state?.restaurantId) {
        return <Redirect to={`/${state?.restaurantId}/checkout`} />;
      } else {
        return <Redirect to="/home" />;
      }
    }
    if (user?.userType === "ADMIN") {
      return <Redirect to="/admin" />;
    }
  }

  console.log(state, "state");

  return (
    <React.Fragment>
      {state?.skip && (
        <div
          style={{
            position: "fixed",
            top: "1rem",
            right: "1rem",
            cursor: "pointer",
          }}
          onClick={() => history.push(state.menu)}
        >
          <Typography>Skip</Typography>
        </div>
      )}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={12} md={6} lg={4}>
          <Paper
            square={true}
            style={{
              padding: "1rem",
              minHeight: "50vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              borderRadius: "10px",
            }}
          >
            <Typography
              style={{ textAlign: "center", margin: "1rem 0rem" }}
              variant={"h6"}
              color="primary"
            >
              Sign in to your account
            </Typography>
            <Form onSubmit={handleSubmit} provider={methods}>
              <Input name="email" label="Email" variant="outlined" />
              <Input
                name="password"
                label="Password"
                type={!showPassword ? "password" : "text"}
                variant="outlined"
                endadornment={
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {!showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <div style={{ marginTop: "0.5rem" }}>
                <Button
                  variant="contained"
                  style={{ width: "100%" }}
                  color="primary"
                  type="submit"
                >
                  Login
                </Button>
              </div>
            </Form>
            <div
              style={{
                marginTop: "1rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography>Don't have an account?</Typography>
              <Button
                color="primary"
                variant="outlined"
                style={{ marginTop: "1rem", width: "100%" }}
                onClick={() => {
                  if (state?.restaurantId) {
                    history.push({
                      pathname: "/registration",
                      state: {
                        restaurantId: state.restaurantId,
                      },
                    });
                  } else {
                    history.push("/registration");
                  }
                }}
              >
                Create Account
              </Button>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "1rem 0rem",
                }}
              >
                <Divider style={{ width: "100px" }} />
                <Typography style={{ margin: "0rem 1rem" }}>OR</Typography>
                <Divider style={{ width: "100px" }} />
              </div>
              <Button
                variant="contained"
                color="primary"
                style={{ width: "100%" }}
                onClick={handleGoogleSignIn}
              >
                Sign in with google
              </Button>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
