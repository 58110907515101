import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Container,
  Button,
  Box,
  TextareaAutosize,
  Toolbar,
  Drawer,
  IconButton,
  GridList,
  GridListTile,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { NavBar } from "./navigation/NavBar";
import { useSelector, useDispatch } from "react-redux";
import { numberWithCommas } from "../../utils/numbersWithComma";
import moment from "moment";
import { Form, Input } from "../../components";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { setTip } from "../../redux/cartReducer/actions";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import {
  DayPicker,
  SingleDatePicker,
  isInclusivelyAfterDay,
} from "react-dates";
import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import {
  setSelectedServiceOption,
  setOrderProcessingDate,
  setSelectedAddress,
} from "../../redux/publicReducer/actions";
import { useHistory, useRouteMatch } from "react-router-dom";
import Select from "react-select";
import { db } from "../../firebase/config";
import VISA from "../../assets/visa.png";
import AMEX from "../../assets/AMEX.png";
import MASTERCARD from "../../assets/mastercard.png";
import {
  setModeOfPayment,
  setPromoCode,
  setTotalDiscount,
} from "../../redux/cartReducer/actions";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { useCookies } from "react-cookie";
import CARDICONS from "../../assets/CARDICONS.png";
import GIFTCARDS from "../../assets/GIFTCARD.png";
import COIN from "../../assets/COIN.png";
import DrawerRight from "features/eComm/components/DrawerRight";
import { rewardsService } from "../../services";
import { RewardsAsPayment } from "./rewards";
import { GiftCardAsPayment } from "./giftcards";
import axios from "axios";
import ScrollToDown from "components/scroll/ScrollToDown";
//import ChangeTextSize from "components/changeText/ChangeTextSize";
import PaymentNested from "./components/PaymentNested";
import { MenuNavigation } from "./components/MenuNavigation";
import LogoCenter from "./components/LogoCenter";
import BottomCheckOutNav from "features/eComm/components/BottomCheckOutNav";
import BlankSpace from "features/eComm/components/BlankSpace";

const serverId = process.env.REACT_APP_SERVER_ID;
const site = process.env.REACT_APP_SITE;
const siteId = process.env.REACT_APP_SITE_ID;
const storeId = process.env.REACT_APP_STORE_ID;
const siteImgSrc = process.env.REACT_APP_IMAGE_SRC;

export default function CheckoutMini() {
  const [openTips, setOpenTips] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [tax, setTax] = useState();
  const history = useHistory();
  const [openServiceOptions, setOpenServiceOptions] = useState(false);
  const [openUserCards, setOpenUserCards] = useState(false);
  const [tempPromoCode, setTempPromoCode] = useState("");
  const [cookiePayment, setCookiePayment, removeCookiePayment] = useCookies([
    "payment",
  ]);

  const {
    cartItems,
    totalPriceItems,
    tips,
    selectedPayment,
    promoCode,
    orderSession,
  } = useSelector((state) => state.cart);
  const {
    orderProcessingDate,
    orderProcessingType,
    selectedAddress,
    user,
    loading,
  } = useSelector((state) => state.public);
  const dispatch = useDispatch();

  const initialValues = {
    message: "",
  };

  const validationSchema = Yup.object({
    message: Yup.string(),
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { watch, setValue } = methods;
  const watchMessage = watch("message");
  // console.log(watchMessage, "MESSAGE");
  const handleSubmit = (d) => {
    // console.log(d);
  };

  const setKitchenMsg = (e) => {
    sessionStorage.setItem("instuctions", e.target.value);
  };

  useEffect(() => {
    const url =
      "https://ecommv2.servingintel.com/items/tax/" +
      siteId +
      "?server_id=" +
      serverId;
    axios
      .get(url)
      .then((response) => {
        setTax(
          parseFloat(parseFloat(response.data[0].TaxPercent / 100) / 100) / 100
        );
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    // console.log("pc:" + promoCode);
    if (cookiePayment?.payment?.id) {
      dispatch(setModeOfPayment(cookiePayment.payment));
    }
    if (cookiePayment?.tips) {
      dispatch(setTip(cookiePayment.tips));
    }
    if (cookiePayment.message) {
      setValue("message", cookiePayment.message);
    }
    if (cookiePayment.promoCode) {
      dispatch(setPromoCode(cookiePayment.promoCode));
      setTempPromoCode(cookiePayment.promoCode);
    }
  }, []);

  //console.log(cookiePayment, "COOKIE");
  const getCookie = (key) => {
    var keyValue = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");
    return keyValue ? keyValue[2] : null;
  };

  useEffect(() => {
    // if (user?.address) {
    //   dispatch(setSelectedAddress(user.address));
    // }
    const address = getCookie("sic_address");
    if (address) {
      dispatch(setSelectedAddress(JSON.parse(address)));
    }
  }, []);

  useEffect(() => {
    if (selectedPayment) {
      setCookiePayment("payment", selectedPayment, { path: "/" });
    } else {
      removeCookiePayment("payment", { path: "/" });
    }
    if (tips) {
      setCookiePayment("tips", tips, { path: "/" });
    } else {
      removeCookiePayment("tips", { path: "/" });
    }

    if (promoCode) {
      setCookiePayment("promoCode", promoCode, { path: "/" });
    } else {
      removeCookiePayment("promoCode", { path: "/" });
    }

    if (watchMessage) {
      setCookiePayment("message", watchMessage, { path: "/" });
    } else {
      removeCookiePayment("message", { path: "/" });
    }
  }, [selectedPayment, tips, watchMessage, promoCode]);

  useEffect(() => {
    if (tempPromoCode || promoCode) {
      setDiscount(0);
      setDiscountPercentage(0);
      const tpc =
        tempPromoCode == ""
          ? promoCode != ""
            ? promoCode
            : "coupon"
          : promoCode;
      const url =
        "https://ecommv2.servingintel.com/payment/coupon/" +
        serverId +
        "/" +
        siteId +
        "/" +
        tpc;
      //const url = "/servingintel_api/public/items/all";
      axios
        .get(url)
        .then((response) => {
          if (response.data.length == 0) {
            dispatch(setPromoCode(""));
            //alert("Invalid Coupon");
          } else {
            const dept = response.data.department;
            const percent = response.data.discount_percent;
            const discount = response.data.discount;
            const subTotal = response.data.subTotal;

            setDiscountPercentage(parseFloat(percent * 100));

            if (dept !== "") {
              //by deparment
              let totalDiscountPrice = 0;
              cartItems.forEach((item) => {
                if (item.Department == dept) {
                  totalDiscountPrice =
                    parseFloat(totalDiscountPrice) +
                    parseFloat((item.DefaultPrice / 100) * item.quantity);
                }
              });

              if (totalDiscountPrice > 0) {
                //applicable items
                if (discount > 0) {
                  // direct amount discount
                  if (totalPriceItems >= subTotal) {
                    setDiscount(parseFloat(discount));
                  } else {
                    //dispatch(setPromoCode(""));
                    //alert("Total item(s) price did not meet the minimum required for this coupon which is $"+subTotal+".")
                  }
                } else {
                  //percentage discount
                  if (totalPriceItems >= subTotal) {
                    setDiscount(parseFloat(totalDiscountPrice * percent));
                  } else {
                    //dispatch(setPromoCode(""));
                    //alert("Total item(s) price did not meet the minimum required for this coupon which is $"+subTotal+".")
                  }
                }
              } else {
                dispatch(setPromoCode(""));
                //alert("Coupon is not applicable to the existing Check Item(s).")
              }
            } else {
              //all items
              setDiscount(parseFloat(totalPriceItems * percent));
            }
          }
        })
        .catch((error) => {});
    }
  }, [totalPriceItems]);

  useEffect(() => {
    dispatch(setTotalDiscount(discount));
  }, [discount]);

  const applyCoupon = () => {
    dispatch(setPromoCode(tempPromoCode));
    setDiscount(0);
    setDiscountPercentage(0);

    const url =
      "https://ecommv2.servingintel.com/payment/coupon/" +
      serverId +
      "/" +
      siteId +
      "/" +
      tempPromoCode;
    //const url = "/servingintel_api/public/items/all";
    axios
      .get(url)
      .then((response) => {
        if (response.data.length == 0) {
          dispatch(setPromoCode(""));
          alert("Invalid Coupon");
        } else {
          const dept = response.data.department;
          const percent = response.data.discount_percent;
          const discount = response.data.discount;
          const subTotal = response.data.subTotal;

          setDiscountPercentage(parseFloat(percent * 100));

          if (dept !== "") {
            //by deparment
            let totalDiscountPrice = 0;
            cartItems.forEach((item) => {
              if (item.Department == dept) {
                totalDiscountPrice =
                  parseFloat(totalDiscountPrice) +
                  parseFloat((item.DefaultPrice / 100) * item.quantity);
              }
            });

            if (totalDiscountPrice > 0) {
              //applicable items
              if (discount > 0) {
                // direct amount discount
                if (totalPriceItems >= subTotal) {
                  setDiscount(parseFloat(discount));
                } else {
                  dispatch(setPromoCode(""));
                  alert(
                    "Total item(s) price did not meet the minimum required for this coupon which is $" +
                      subTotal +
                      "."
                  );
                }
              } else {
                //percentage discount
                if (totalPriceItems >= subTotal) {
                  setDiscount(parseFloat(totalDiscountPrice * percent));
                } else {
                  dispatch(setPromoCode(""));
                  alert(
                    "Total item(s) price did not meet the minimum required for this coupon which is $" +
                      subTotal +
                      "."
                  );
                }
              }
            } else {
              dispatch(setPromoCode(""));
              alert("Coupon is not applicable to the existing Check Item(s).");
            }
          } else {
            //all items
            setDiscount(parseFloat(totalPriceItems * percent));
          }
        }
      })
      .catch((error) => {});
  };

  return (
    <React.Fragment>
      <Container
        style={{
          position: "relative",
          height: "90vh",
          maxHeight: "90vh",
          /*overflowY: "auto",*/
          backgroundColor: "transparent",
        }}
      >
        {/* <Typography
          variant="subtitle1"
          style={{ fontWeight: 800 }}
          gutterBottom
        >
          MY ORDER{" "}
          <Button
            style={{
              textTransform: "none",
              color: "gray",
              fontSize: "0.8rem",
            }}
            onClick={() => history.push("/menu")}
          >
            Add More
          </Button>
        </Typography> */}
        <Box height="0rem" />
        <Grid container spacing={1}>
          {cartItems.length === 0 && (
            <Typography
              style={{
                textAlign: "center",
                width: "100%",
                color: "#bebebe",
                backgroundColor: "transparent",
              }}
              variant="h6"
            >
              NO ITEMS IN CHECK!
            </Typography>
          )}
          {cartItems.map((item) => {
            const modifiers = item.modifiers.sort(
              (a, b) => parseInt(a.ModifierIndex) - parseInt(b.ModifierIndex)
            );

            return (
              <React.Fragment key={item.ItemID}>
                
                <Grid item xs={12} sx={{ backgroundColor: "transparent" }}>
                  <Typography variant="subtitle2">{item.ItemName}</Typography>
                  <Typography variant="caption">
                    $ {numberWithCommas(item.DefaultPrice / 100)}
                  </Typography>
                  {item.modifiers ? (
                    <Typography variant="subtitle2">Add-On:</Typography>
                  ) : null}
                  {item.modifiers
                    ? modifiers.map((mod) => (
                        <>
                          <Typography variant="subtitle2">
                            {mod.ItemDescription} - ${" "}
                            {numberWithCommas(mod.DefaultPrice / 100)}
                          </Typography>
                        </>
                      ))
                    : null}
                </Grid>
                <Grid item xs={3} sx={{ backgroundColor: "transparent" }}>
                  <Grid container justifyContent="flex-end">
                    <Box
                      border="1px solid #cecece"
                      borderRadius="5px"
                      padding="1px"
                      display="flex"
                      alignItems="center"
                    >
                      <RemoveIcon
                        fontSize="small"
                        onClick={() => {
                          if (item.quantity > 1) {
                            const newCartItems = cartItems.map((cartItem) => {
                              if (cartItem.ItemID === item.ItemID) {
                                return { ...item, quantity: item.quantity - 1 };
                              }

                              return cartItem;
                            });

                            dispatch({
                              type: "SET_CART",
                              payload: newCartItems,
                            });
                          } else {
                            const filterCartItems = cartItems
                              .map((i) => i)
                              .filter((j) => j !== item);
                            dispatch({
                              type: "SET_CART",
                              payload: filterCartItems,
                            });
                          }
                        }}
                      />
                      <Box margin="0rem 6px">{item.quantity}</Box>
                      <AddIcon
                        fontSize="small"
                        onClick={() => {
                          const newCartItems = cartItems.map((i) => {
                            if (i === item) {
                              return { ...i, quantity: i.quantity + 1 };
                            }
                            return i;
                          });

                          dispatch({ type: "SET_CART", payload: newCartItems });
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
      </Container>
    </React.Fragment>
  );
}
