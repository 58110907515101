import ReactPlayer from "react-player";
import "../../../App.css";
import { Container, Card, Row, Col, h5, p, h2, div } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export const OrderSlider = () => {
  return (
      
<div className="OrderSlider">
      <div className="OrderHeader">
        <Container>
          <Row>
            <col className="whitespace"></col>
            <Col sm={12} lg={8}>
              <h1 className="left white">
                Eat at AJs!
              </h1>
                <h5 className="white">
                {" "}
                Why is there no sugar in any item at AJ Sliders ? Sugar is caustic to the human body. It causes inflammation and feeds cancers. Wise people actively remove it from their diets.
              </h5>
              <h1 className="left white whitespace">
                Ready for free pie?
              </h1>
              <h5 className="white">
                {" "}
                Get a free 4” with any pickup order using coupon code FREEPIE ~or~ a free 9” with any pickup order >$20 with coupon code MOREFREEPIE. This offer is available only at www.ajsliders.com/order Offer expires 1/31/2022. 
              </h5>                
            </Col>
            <Col sm={0} lg={4}>
    {/*<Card>
        <Card.Img src="/static/1.jpg" />
    </Card> */}
            </Col>
           <col className="whitespace"></col>
          </Row>
        </Container>
      </div>
</div>
    

  );
};
