import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { numberWithCommas } from "../../../utils/numbersWithComma";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import axios from "axios";

const siteImgSrc = process.env.REACT_APP_IMAGE_SRC;
const site = process.env.REACT_APP_SITE;
const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;

export default function FoodCard(props) {
  const isMobile = useMediaQuery("(max-width:600px)");

  let current_url = window.location.href;
  current_url = current_url.split("/");

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      margin: theme.spacing(2),
      height: "auto",
      position: "relative",
      backgroundColor: "bisque",
      cursor: "pointer",
      borderBottom: isMobile && "2px solid #f2f2f2",
      border: !isMobile && "2px solid #f2f2f2",
      alignItems: "center",
    },
    details: {
      // display: "flex",
      // flexDirection: "column",
      //width: isMobile ? "75%" : "50%",
      height: "150px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      webkitLineClamp: "2" /* number of lines to show */,
      webkitBoxOrient: "vertical",
    },
    price: {
      position: "absolute",
      bottom: "5px",
      color: "black",
    },
    content: {
      // flex: "0 1 auto",
      width: "100%",
      wordWrap: "break-word",
      lineClamp: 3,
    },
    cover: {
      width: isMobile ? "25%" : "50%",
      display: "none",
    },
    controls: {
      display: "flex",
      alignItems: "center",
      paddingLeft: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    playIcon: {
      height: 38,
      width: 38,
    },
  }));
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  const [itemSchedule, setItemSchedule] = useState();
  const [isItemAvailable, setIsItemAvailable] = useState(true);
  const [available, setAvailable] = useState();
  const [notAvailable, setNotAvailable] = useState();

  useEffect(() => {
    if (isItemAvailable == false) {
      props.addRemoveItems(props.product.ItemID);
    }
  }, [isItemAvailable]);
  //get item schedule
  useEffect(() => {
    const url =
      "https://ecommv2.servingintel.com/items/schedule/" +
      serverId +
      "/" +
      siteId +
      "/" +
      props.product.ItemID;
    axios
      .get(url)
      .then((response) => {
        const data = response.data;
        setItemSchedule(data);

        const a = data.filter((i) => i.Availability == "Available");

        setAvailable(a);
        if (a[0]) {
          const current_date = new Date();
          const availableDate = new Date(a[0].Date);

          if (availableDate.getTime() > current_date.getTime()) {
            setIsItemAvailable(false);
          }
        }
        const na = data.filter((i) => i.Availability == "Not Available");
        setNotAvailable(na);
        if (na[0]) {
          const current_date = new Date();
          const notAvailableDate = new Date(na[0].Date);

          if (
            parseInt(notAvailableDate.getTime()) <=
            parseInt(current_date.getTime())
          ) {
            setIsItemAvailable(false);
          }
        }
      })
      .catch((error) => {
        return;
      });
  }, [props]);

  return (
    <>
      {isItemAvailable ? (
        <Card
          className={classes.root}
          square
          elevation={0}
          onClick={() => {
            const productBaseUrl =
              current_url[3] !== "menu" ? props.categoryName : "menu";
            props.getSelectedProduct(props.product.ItemID);
            if (isItemAvailable) {
              history.push({
                pathname:
                  "/" +
                  productBaseUrl +
                  "/" +
                  props.product.ItemName.replace(/ /g, "-"),
                state: {
                  revenueCenter: props.revenueCenter,
                  menuName: props.menuName,
                },
              });
            }
          }}
        >
          <CardMedia className={classes.cover} title={props.product.ItemName}>
            <img
              src={
                props.product.FileName && props.product.FileName.length > 0
                  ? siteImgSrc + encodeURIComponent(props.product.FileName)
                  : site + "/default.jpg"
              }
              style={{ opacity: "0" }}
            />
          </CardMedia>
          <div className={classes.details}>
            <CardContent className={classes.content}>
              <Typography
                component="h6"
                variant="subtitle1"
                style={{ fontWeight: "bold" }}
              >
                {props.product.ItemName}{" "}
                {isItemAvailable ? "" : " - Not Available"}
              </Typography>
              <Typography
                style={{
                  textOverflow: "ellipsis",
                  // whiteSpace: "nowrap",
                  overflow: "hidden",
                  fontSize: "1rem",
                }}
                variant="caption"
              >
                {isMobile
                  ? props.product.ItemDescription.length > 50
                    ? props.product.ItemDescription.substring(0, 50 - 3) + "..."
                    : props.product.ItemDescription.substring(0, 50)
                  : props.product.ItemDescription.length > 70
                  ? props.product.ItemDescription.substring(0, 70 - 3) + "..."
                  : props.product.ItemDescription.substring(0, 70)}
              </Typography>
              <Typography
                className={classes.price}
                variant="subtitle2"
                color="textSecondary"
                style={{ fontWeight: "bold" }}
              >
                $ {numberWithCommas(props.product.DefaultPrice / 100)}
              </Typography>
            </CardContent>
            <CardActions></CardActions>
          </div>
        </Card>
      ) : null}
    </>
  );
}
