import React, { useEffect, useState, useRef } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { Divider, IconButton, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { db } from "../../../firebase/config";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SendIcon from "@material-ui/icons/Send";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import moment from "moment";
import { Form, Input } from "../../../components";

export const AdminMessaging = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const { user } = useSelector((state) => state.public);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [count, setCount] = useState(0);

  const [messages, setMessages] = useState([]);
  const userRef = db.collection("users");
  const chatRef = db
    .collection("admins")
    .doc(user.id)
    .collection("messaging")
    .doc(match.params.chatId);
  const [customer, setCustomer] = useState({});
  const lastChat = useRef();

  const initialValues = {
    message: "",
  };

  const validationSchema = Yup.object({
    message: Yup.string().required("Type a message"),
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { reset } = methods;

  const sendMessage = (data) => {
    console.log(data, "data");

    chatRef
      .collection("messages")
      .add({
        createdAt: new Date(),
        id: user.id,
        ...data,
      })
      .then(() => {
        reset(initialValues);
        lastChat.current.scrollIntoView({ behavior: "smooth" });
      });
  };

  useEffect(() => {
    const unsub = userRef.doc(match.params.chatId).onSnapshot((doc) => {
      setCustomer(doc.data());
    });

    return unsub;
  }, []);

  useEffect(() => {
    const unsub = chatRef
      .collection("messages")
      .orderBy("createdAt", "asc")
      .onSnapshot((snapshot) => {
        setMessages(
          snapshot.docs.map((doc) => {
            console.log(doc.data());
            return { ...doc.data() };
          })
        );
        setCount(snapshot.docs.length);

        setHasLoaded(true);
      });

    return unsub;
  }, []);

  useEffect(() => {
    if (lastChat.current && hasLoaded && count) {
      console.log("logging");
      lastChat.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [lastChat?.current, hasLoaded, count]);

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center", padding: "1rem" }}>
        <IconButton
          style={{ marginRight: "1rem" }}
          onClick={() => {
            history.goBack();
          }}
        >
          <ArrowBackIcon />
        </IconButton>
        <div>
          <Typography variant="subtitle1">{customer.name}</Typography>
          <Typography variant="subtitle2">{customer.email}</Typography>
        </div>
      </div>
      <Divider />
      {hasLoaded && (
        <div style={{ padding: "1rem", maxHeight: "80vh", overflowY: "auto" }}>
          {messages.map((message, index) => {
            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  justifyContent:
                    message.id === user.id ? "flex-end" : "flex-start",
                  marginBottom: "1rem",
                }}
              >
                <div
                  style={{
                    padding: "1rem",
                    borderRadius: "25px",
                    backgroundColor:
                      message.id === user.id ? "#87CEEB" : "#f5f5f5",
                  }}
                >
                  <Typography>{message.message}</Typography>
                </div>
              </div>
            );
          })}
          <div ref={lastChat} />
        </div>
      )}
      <div
        style={{
          padding: "0rem 1rem",
          marginTop: "auto",
          position: "absolute",
          bottom: "0px",
          width: "100%",
        }}
      >
        <Form onSubmit={sendMessage} provider={methods}>
          <Input
            name="message"
            variant={"outlined"}
            label="Send a message"
            endadornment={
              <IconButton type="submit">
                <SendIcon />
              </IconButton>
            }
          />
        </Form>
      </div>
    </div>
  );
};
