import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Fab from "@mui/material/Fab";
import LogoutTwoToneIcon from "@mui/icons-material/LogoutTwoTone";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackTwoToneIcon from "@mui/icons-material/ArrowBackTwoTone";
import MoreIcon from "@mui/icons-material/MoreVert";
import silogo from "../../../assets/images/si-logo.svg";
import Link from "@mui/material/Link";
import FavoriteTwoToneIcon from "@mui/icons-material/FavoriteTwoTone";
import axios from "axios";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const site = process.env.REACT_APP_SITE;
const storeId = process.env.REACT_APP_STORE_ID;

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
          {/* <br></br>
      Made with <FavoriteTwoToneIcon sx={{ color: "#820813" }} /> by<br></br>
      <Link color="inherit" target="_blank" href="https://servingintel.com/">
        <img className="silogo" src={silogo} alt="ServingIntel" />
      </Link>{" "} */}
    </Typography>
  );
}

const StyledFab = styled(Fab)({
  position: "absolute",
  zIndex: 1,
  top: 0,
  left: 0,
  right: 0,
  margin: "0 auto",
  width: "360px",
  backgroundColor: "transparent !important",
  boxShadow: "none !important",
  textTransform: "none",
});

export default function TopWelcomeNav() {
    
  const [mealBalance, setMealBalance] = useState();
  const [mealBalanceDollars, setMealBalanceDollars] = useState();
  const [firstName, setFirstName] = useState(
    sessionStorage.getItem("firstName")
  );     
  const [roomNumber, setRoomNumber] = useState(
    sessionStorage.getItem("roomNumber")
  );    
    
  useEffect(() => {
    const url =
      "https://ecommv2.servingintel.com/customer/login/" +
      serverId +
      "/" +
      siteId +
      "/" +
      storeId +
      "/" +
      firstName +
      "/" +
      roomNumber;
    axios
      .get(url)
      .then((response) => {
        const customer = response.data;
        if (
          customer.FirstName.toLowerCase() === firstName.toLowerCase() &&
          customer.Address1.toLowerCase() === roomNumber.toLowerCase()
        ) {
          setMealBalance(customer.MealCount);
          setMealBalanceDollars(customer.CreditLimit - customer.AvailableCredit);
        } else {
          window.location.href = site;
        }
      })
      .catch((error) => {});
  }, []);
    
  return (
    <React.Fragment>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          top: 10,
          bottom: "auto",
          backgroundColor: "transparent",
        }}
      >
        <Toolbar>
      <Box
        sx={{
          mx: 'auto',
          width: 350,
          p: 1,
          bgcolor: (theme) =>
            theme.palette.mode === 'dark' ? '#101010' : 'grey.50',
          color: (theme) =>
            theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
          border: '1px solid',
          borderColor: (theme) =>
            theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
          borderRadius: 2,
          textAlign: 'left',
          fontSize: '0.875rem',
          fontWeight: '700',
          marginLeft: 'auto !important',
          marginRight: 'auto !important',
        }}
      >
              
          <Typography variant="h6"  component="div" align="left">Good day <b>{firstName}</b>. Your <b>Meal Credit Available</b> is <b>{mealBalance}</b> </Typography>
          <Typography variant="h6"  component="div" align="left">Good day <b>{firstName}</b>. Your <b>Current Balance</b> is <b>{mealBalanceDollars}</b> </Typography>
              
      </Box>
          <StyledFab disableautofocus disableenforcefocus>
            <Copyright position="centered" sx={{ top: "auto", bottom: 0 }} />
          </StyledFab>
          <Box sx={{ flexGrow: 1 }} />
          {/* <IconButton
            color="inherit"
            title = "Logout"
            href="/"
            aria-label=""
            sx={{ transform: "scale(1.5)", backgroundColor: "#004a4b" }}
          >
               <LogoutTwoToneIcon />
          </IconButton> */}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
