import React from "react";
import { AppBar, Toolbar, Typography } from "@material-ui/core";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import { useSelector } from "react-redux";
import { auth } from "../../firebase/config";
import { useHistory } from "react-router-dom";

export const NavDriver = () => {
  const { user } = useSelector((state) => state.public);
  const history = useHistory();
  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6">
          MorningSide <DriveEtaIcon />
        </Typography>
      </Toolbar>
    </AppBar>
  );
};
